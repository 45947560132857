'use client';

import { createContext, ReactNode, useContext } from 'react';

const MobileDetectorContext = createContext<{ isMobile: boolean }>({ isMobile: false });

const MobileDetectorProvider = ({ children, isMobile }: { children: ReactNode; isMobile: boolean }) => (
  <MobileDetectorContext.Provider value={{ isMobile }}>{children}</MobileDetectorContext.Provider>
);

const useIsMobile = () => {
  const context = useContext(MobileDetectorContext);
  if (!context) {
    throw new Error('useIsMobile must be used within a MobileDetectorProvider');
  }
  return context;
};

export { MobileDetectorProvider, useIsMobile };
